define("discourse/plugins/discourse-auto-lock-pms/discourse/templates/connectors/composer-fields/toggle-prevent-reply", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input @type="checkbox" @checked={{mut this.model.auto_lock_pm}} />
  {{i18n "discourse_auto_lock_pms.composer_toggle_label"}}
  */
  {
    "id": "DoMpKeiJ",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@checked\"],[\"checkbox\",[28,[37,1],[[30,0,[\"model\",\"auto_lock_pm\"]]],null]]],null],[1,\"\\n\"],[1,[28,[35,2],[\"discourse_auto_lock_pms.composer_toggle_label\"],null]]],[],false,[\"input\",\"mut\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-auto-lock-pms/discourse/templates/connectors/composer-fields/toggle-prevent-reply.hbs",
    "isStrictMode": false
  });
});