define("discourse/plugins/discourse-auto-lock-pms/discourse/connectors/composer-fields/toggle-prevent-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args) {
      if (args.model.siteSettings.discourse_auto_lock_pms_enabled && args.model.action === "privateMessage") {
        return true;
      }
      return false;
    }
  };
});