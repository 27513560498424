define("discourse/plugins/discourse-auto-lock-pms/discourse/api-initializers/discourse-auto-lock-pms", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.11.1", api => {
    api.serializeOnCreate("auto_lock_pm");
    api.serializeToDraft("auto_lock_pm");
  });
});